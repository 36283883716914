// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require("helpers/confetti.js")
require("helpers/prism.js")

// require("cloud-cookie-sdk")
require("helpers/cloudcookie")
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(function(){
  $(document).on('click','.copyMe',function(){
    $button = $(this);
    try {
      var el = document.createElement('textarea');
      el.value = $(this).siblings('code').text();
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);  
      $button.hide().html('Copied!').addClass('btn-success').fadeIn('fast');
      setTimeout(function(){
        $button.hide().html('Copy').removeClass('btn-success').fadeIn('fast');
      },3000)
    }catch(err) {
      $button.hide().html('Press &#8984;+C to copy.').fadeIn('fast');
    }
  })

  $(document).on('click','.nav-link',function(e){
    if( $(this).attr('href')=='#npm' ){
      e.preventDefault()
      $('[id=npm-tab]').addClass('active')
      $('[id=npm]').tab('show');
      $('[id=cdn-tab]').removeClass('active')
      $('[id=cdn]').removeClass('active show');
    }else if( $(this).attr('href')=='#cdn' ){
      e.preventDefault()
      $('[id=cdn-tab]').addClass('active')
      $('[id=cdn]').tab('show');
      $('[id=npm-tab]').removeClass('active')
      $('[id=npm]').removeClass('active show');
    }
  })

})




//CONTROLLER To send arbitrary AJAX requests; Handles errors, success element, and button disabled/enabled state.
//On success, the server should return JSON object with r.success = true; And any other data needed in the callback
//On failure, the server should return JSON object with r.success = false, and r.errors (an array of errors to be printed to the user)
//Options should be specified such as:
//options={
//	url:'credit_card.json', //REQUIRED: url
//	data:myData,            //REQUIRED: data to post
//  type:'POST',            //Optional, type of request (POST by default)
//	buttonElement: $(''),   //Optional, the 'submit' button, which will be disabled
//	errorsElement: $(''),   //Optional, The errors element, in which errors will be displayed
//	successElement: $('')	//Optional, the success element which will temporarily fade in on success
//};
//Callback will return the server response if server can be reached. Otherwise will return an object with r.success = false
window.ajaxController = function(options, callback) {
  var a = this;
  a.url = options.url;
  a.data = options.data;

  if (typeof options.errorsElement != 'undefined') {
    a.errorsElement = options.errorsElement;
    a.errorsElement.hide();
    a.err = new errorHandler(a.errorsElement); //Create and assign error handler for user errors
    a.err.clearErrors(); //Clear errors
  }
  if (typeof options.buttonElement != 'undefined') {
    a.buttonElement = options.buttonElement;
    a.buttonElement.toggleButton(); //Toggle button state
  }
  if (typeof options.successElement != 'undefined') {
    a.successElement = options.successElement;
    a.successElement.fadeOut('fast');
  }
  if (typeof options.type == 'undefined') {
    a.type = 'POST';
  } else {
    a.type = options.type;
  }
  if (typeof options.flashSuccess == 'undefined') {
    //Whether to flash success, or keep it permanently
    a.flashSuccess = true;
  } else {
    a.flashSuccess = options.flashSuccess;
  }

  var requestObj = {
    url: a.url,
    type: a.type,
    data: a.data,
    beforeSend: options.skipCSRF
      ? function() {}
      : function(xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        }, //Send csrf token
    dataType: options.dataType || 'json',
  };

  if (options) {
    $.each(['contentType', 'processData'], function(index, key) {
      if (key in options) {
        requestObj[key] = options[key];
      }
    });
  }

  var request = $.ajax(requestObj);
  //Callback from server:

  request.done(function(r) {
    if (r.success == true) {
      if (typeof a.successElement != 'undefined') {
        a.successElement.fadeIn('fast'); //Show successful
        if (a.flashSuccess) {
          setTimeout(function() {
            a.successElement.fadeOut('fast');
          }, 2000);
        }
      }
    } else {
      if (typeof a.errorsElement != 'undefined' && typeof r.errors != 'undefined') {
        if (r.errors.length > 0) {
          for (var i = 0; i < r.errors.length; i++) {
            a.err.addError(r.errors[i]);
          }
          var error = a.err.errors.join('<br/>');
          a.errorsElement.html(error).show();
        }
      }
    }

    if (typeof a.buttonElement != 'undefined') {
      a.buttonElement.toggleButton(); //Toggle the button again
    }

    if (typeof callback != 'undefined') {
      callback(r);
    }
  });

  request.fail(function(r) {
    try {
      var error = r.responseJSON.error;
      if( typeof a.errorsElement != 'undefined'){
        a.errorsElement.html(error).show();
      }
    } catch (e) {
      if (typeof a.errorsElement != 'undefined') {
        a.err.addError(
          'Difficulty contacting server. Please try again or contact <a href="mailto:support@cloudcookie.io"><u>support</u></a>'
        );
        a.errorsElement.html(error).show();
      }
    } finally {
      if (typeof a.buttonElement != 'undefined') {
        a.buttonElement.toggleButton(); //Toggle the button again
      }
      if (typeof callback != 'undefined') {
        try {
          r = r.responseJSON;
        } catch (error) {
          r = { success: false, errors: ['Something went wrong, please try again or contact support@cloudcookie.io'] };
        }
        callback(r);
      }
    }
  });
};
window.errorHandler = function(element) {
  var e = this;
  e.errors = []; //Errors is an array
  e.element = element; //Jquery selected element where errors appear
  //To add an error
  e.addError = function(text) {
    e.errors.push(text);
  };
  //Display all errors in the error array
  e.displayErrors = function() {
    if (e.errors.length > 0) {
      text = '';
      text += '<ul>';
      for (var i = 0; i < e.errors.length; i++) {
        text += '<li>' + e.errors[i] + '</li>';
      }
      text += '</ul>';
      e.element.html(text);
      e.element.fadeIn('slow');
    }
  };
  //Clear error html and fade out
  e.clearErrors = function() {
    e.errors = [];
    e.element.hide().html('');
  };
};
//Toggle a button: changes status from disabled and toggles class "loading"
jQuery.fn.toggleButton = function() {
  var elements = $(this); // The element
  elements.each(function() {
    this.disabled = !this.disabled; // Toggle disabled status
    $(this).toggleClass('loading'); // Toggle loading class
  });
};
